import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component";

import ImageBox from "./john-motion/motion-box/image-box";
import { makeHover } from "../style/base-styles";
import { Paragraph } from "./type";

const TinySlider = loadable(() => import("tiny-slider-react"));

export function SlideCarousel({ slides, settings }) {
  if (typeof window !== "undefined") {
    const sliderSettings = {
      nav: true,
      mouseDrag: true,
      loop: false,
      gutter: 1,
      items: 1.1,
      responsive: {
        768: {
          items: 1.16,
        },
      },
      ...settings,
    };

    return (
      <SliderWrapper>
        <TinySlider settings={sliderSettings}>
          {slides.map(({ image, gallery_images }, index) => (
            <div key={index} style={{ position: "relative" }}>
              {(image?.alt || gallery_images?.alt) && (
                <SlideText className="image-alt">{image.alt}</SlideText>
              )}
              <ImageBox
                fluid={gallery_images?.fluid || image?.fluid}
                hiddenStyles={{ x: 0, y: 0, scale: 0.99 }}
                alt={gallery_images?.alt || image?.alt}
              />
            </div>
          ))}
        </TinySlider>
      </SliderWrapper>
    );
  }
  return null;
}

const SlideText = styled(Paragraph)`
  position: absolute;
  bottom: 1.5rem;
  left: ${({ theme }) => theme.layout.outerRailBase};
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css},
    opacity ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css};
  transition-delay: 300ms;
  opacity: 1;
  transform: none;
  z-index: 2;

  @media ${({ theme }) => theme.device.tablet} {
    bottom: calc(2vw + 4rem);
    left: 4vw;
  }
`;

const SliderWrapper = styled.section`
  overflow: hidden;
  border-top: 3px solid ${({ theme }) => theme.colors.golden};
  border-bottom: 3px solid ${({ theme }) => theme.colors.golden};

  * {
    user-select: none;
  }

  .title {
    padding-bottom: ${({ theme }) => theme.layout.space[200]};
  }

  .tns-outer {
    background: ${({ theme }) => theme.colors.evergreen};
  }

  .tns-ovh,
  .tns-inner {
    overflow: visible !important;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .tns-item .gatsby-image-wrapper {
    height: 60vmax;
    pointer-events: none;
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      opacity ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css};
    will-change: transform, opacity;
    overflow: hidden;
  }

  .tns-item:not(.tns-slide-active) {
    .gatsby-image-wrapper {
      opacity: 0.3;
    }

    .image-alt {
      opacity: 0;
      transform: translateY(1rem);
    }
  }

  .tns-outer {
    position: relative;
  }

  button[data-action="stop"],
  button[data-action="start"] {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    z-index: 2;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    color: transparent;
    border: none;
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      opacity ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css};
    transform-origin: center center !important;
    will-change: transform;
    opacity: 0.5;

    ${makeHover`
      transform: scale(1.05);
      opacity: 1;
    `}

    &:active {
      transform: scale(0.95);
    }
  }

  button[data-action="stop"] {
    background: url("/svg/icon-pause.svg");
  }

  button[data-action="start"] {
    background: url("/svg/icon-play.svg");
  }

  .tns-nav {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(15, auto);
    grid-auto-flow: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: ${({ theme }) => theme.layout.outerRailBase};
    left: ${({ theme }) => theme.layout.outerRailBase};
    height: 1rem;
    outline: none;
    z-index: 2;

    @media ${({ theme }) => theme.device.tablet} {
      bottom: 2vw;
      left: 4vw;
      height: 4rem;
    }

    button {
      background: blue;
      outline: none;
      border: none;
      width: 0.25rem;
      height: 0.25rem;
      margin: 1rem 0;
      background: ${({ theme }) => theme.colors.golden};
      opacity: 0.3;
      border-radius: 1rem;
      transition: width ${({ theme }) => theme.animation.duration[300].css}
          ${({ theme }) => theme.animation.timingFunction.css},
        opacity ${({ theme }) => theme.animation.duration[100].css}
          ${({ theme }) => theme.animation.timingFunction.css},
        transform ${({ theme }) => theme.animation.duration[100].css}
          ${({ theme }) => theme.animation.timingFunction.css};

      @media ${({ theme }) => theme.device.tablet} {
        width: 2rem;
        height: 0.25rem;
        margin: 1rem 0;
      }

      &.tns-nav-active {
        opacity: 1;
        transform: scaleY(1.5);
        width: 2rem;

        @media ${({ theme }) => theme.device.tablet} {
          width: 4rem;
        }
      }
    }
  }

  .tns-controls {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    outline: none;
    display: none;

    @media ${({ theme }) => theme.device.tablet} {
      display: block;
    }

    button {
      transition: opacity ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css};

      &[data-controls="prev"],
      &[data-controls="next"] {
        position: absolute;
        z-index: 2;
        bottom: 2vw;
        width: 5.625rem;
        height: 4rem;
        /* transform: translateY(-50%); */
        background: transparent;
        color: transparent;
        border: none;

        &[disabled] {
          pointer-events: none;
          opacity: 0.3;
        }

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 5.625rem;
          height: 2rem;
          transition: transform
            ${({ theme }) => theme.animation.duration[300].css}
            ${({ theme }) => theme.animation.timingFunction.css};
          will-change: transform;
        }
      }

      &[data-controls="prev"] {
        right: 15vw;
        transform: translateX(-50%);

        ${makeHover`
          &:before {
            transform: translateY(-50%) translateX(-25%);
          }
        `}

        &:before {
          transform: translateY(-50%);
          background: url("/svg/arrow-large-prev.svg");
        }
      }

      &[data-controls="next"] {
        right: 7vw;
        transform: translateX(50%);

        ${makeHover`
          &:before {
            transform: translateY(-50%) translateX(25%);
          }
        `}

        &:before {
          transform: translateY(-50%);
          background: url("/svg/arrow-large-next.svg");
        }
      }
    }
  }
`;
